<template>
  <v-container class="px-3">
    <h2>{{title}}</h2>
    <v-img v-if="$vuetify.breakpoint.smAndUp && hasImage" class="ml-10 mb-3 mr-3 img" max-width="50%" :src="src"/>
    <p class="text-xs-body-1 text-md-body-2"><slot/></p>
    <v-img v-if="$vuetify.breakpoint.xsOnly && hasImage" :src="src"/>
  </v-container>
</template>

<style lang="sass">
  //p
  //  text-align: left
  //  -webkit-hyphens: auto
  //  -ms-hyphens: auto
  //  hyphens: auto
  //  line-height: 1.5em
  //  font-size: 16px
  //  letter-spacing: .03em
  .img
    float: right
    border-radius: 3px
</style>

<script>
export default {
  props: {
    src: String,
    title: String
  },
  data: () => {
    return {
      hasImage: undefined
    }
  },
  mounted: function () {
    if (typeof this.src === 'undefined') {
      this.hasImage = false
    } else {
      this.hasImage = true
    }
  }
}
</script>
